import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import {
	Button,
	Grid,
	Row, FormGroup,
	Col,
	FormControl,
	OverlayTrigger,
	Tooltip
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './VerificationFields.css';
import { loadAccount } from '../../../actions/account';

// components
import ShowNotification from '../../ShowNotification/ShowNotification';

// Locale
import messages from '../../../locale/messages';
import refreshIcon from '/public/SiteIcons/Refresh.svg';
import Faq from '../../Receipt/question.svg';

class VerificationFields extends Component {
	static propTypes = {
		guestEmail: PropTypes.string.isRequired,
		idNumberPassport: PropTypes.number.isRequired,
		driverLicense: PropTypes.number.isRequired,
		isIdDocVerification: PropTypes.number.isRequired,
		resendEmailVerification: PropTypes.any.isRequired,
		formatMessage: PropTypes.any,
	};

	constructor(props) {
		super(props);
		this.state={
			isHavingError:false
		}
		this.handleClick = this.handleClick.bind(this);
		this.handleShowError = this.handleShowError.bind(this);
		this.handleHideError = this.handleHideError.bind(this);
	}

	handleShowError(){
		this.setState({isHavingError: true});
	}
	  
	handleHideError(){
		this.setState({isHavingError: false});
	}
	async handleClick() {
		const { nextPage, loadAccount, account } = this.props;
		await loadAccount();
		if (account?.verification?.isEmailConfirmed) {
			nextPage('payment');
		}
	}

	render() {
		const { guestEmail, resendEmailVerification,driverLicense,idNumberPassport,isIdDocVerification } = this.props;
		const { formatMessage } = this.props.intl
		const { isHavingError } = this.state;
		const isNotificationIDPassport = idNumberPassport ===1 ? false:true;
		const isNotificationDriverLicense = driverLicense ===1 ? false:true;
		const isShowDocVerification = isIdDocVerification ===1 ? false:true;
		
		const toolTipText = formatMessage(messages.refreshToolTip);

		function LinkWithTooltip({ children, href, tooltip }) {
			return (
				<OverlayTrigger
					overlay={<Tooltip className={s.tooltip}>{tooltip}</Tooltip>}
					placement="top"
					delayShow={300}
					delayHide={150}
				>
					{children}
				</OverlayTrigger>
			);
		}

		return (
			<Grid>
				<Row>
					<div className={s.pageContainer}>
						<div className={s.activationStepPanel}>
							<div className={s.panelBody}>
								<h3 className={s.heading}><FormattedMessage {...messages.needConfirmation} /></h3>
								
								<div className={cx(s.textLead, s.space4)}>
									{`${formatMessage(messages.needConfirmation)} ${formatMessage(messages.pendingVerification)}`}
								</div>
								<Col xs={12} sm={12} md={12} lg={12}>
									<ShowNotification 
										handleHideError={this.handleHideError}
										handleShowError={this.handleShowError}
										showHeader={false}
										showSubTitle={true}
										showEmailConfirmed={true}
										showPhoneNumber={true}
										isShowing={true}
										showAgreement={false}
										showVehicleDoc={false}
										showIDIdentification={true}
										showDriverLicense={true}
										showDocVerification={true}
										listingID={0}
									/>
									
								</Col>
							</div>
						</div>
					</div>
				</Row>
			</Grid>
		);
	}
}

const mapState = (state) => ({
	account: state.account.data,
});

const mapDispatch = {
	loadAccount
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(VerificationFields)));