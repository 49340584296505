// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

// Redux Form
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import PasswordStrengthBar from 'react-password-strength-bar';
import CommonFormComponent from '../CommonField/CommonFormComponent';
import { graphql, gql, compose } from 'react-apollo';

import submit from './submit';
import validate from './validate';


// Components
// import ChangePasswordForm from '../../components/ChangePasswordForm';

// Locale
import messages from '../../locale/messages';

// Redux
import { connect } from 'react-redux';

// Query
import userAccountQuery from './userAccountQuery.graphql';

// Helper
import PopulateData from '../../helpers/populateData';
import Loader from '../../components/Loader';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from './EditProfileForm.css';
import cs from '../../components/commonStyle.css';
import {
  Button,
  Form,
  FormGroup,
  FormControl,
  InputGroup
} from 'react-bootstrap';

// Internal Components
// import PhoneVerificationModal from '../PhoneVerificationModal';
import { updateUserPassword } from '../../actions/userManagerSecurity';

import CountryList from '../CountryList';
import history from '../../core/history';

//Images
import infoImage from '/public/SiteIcons/priceHoverIcon.svg';
import ShowPassword from '/public/SiteIcons/pswVisible.svg';
import HidePassword from '/public/SiteIcons/pwdHidden.svg';


class EditProfileForm extends Component {

  static propTypes = {
    loadAccount: PropTypes.func,
    updateUserPassword: PropTypes.any.isRequired,
    formatMessage: PropTypes.func,
    registeredType: PropTypes.string,
    allConfirmed: PropTypes.bool,
    loadingUpdatePassword: PropTypes.bool,
    userAccountData: PropTypes.shape({
      loading: PropTypes.bool,
      userAccountExt: PropTypes.shape({
          // userId: PropTypes.string.isRequired,
          email: PropTypes.string.isRequired,
          displayName: PropTypes.string.isRequired,
          firstName: PropTypes.string.isRequired,
          // phoneNumber: PropTypes.string,
          verification: PropTypes.shape({
              isEmailConfirmed: PropTypes.bool.isRequired,
              isIdNumberVerification: PropTypes.number.isRequired,
              isLicenseVerified: PropTypes.number.isRequired,
              isIdVerification: PropTypes.number.isRequired,
          }),
          userProfileData: PropTypes.shape({
              isAgreementSigned: PropTypes.number.isRequired,
              driver_license_verify:PropTypes.number.isRequired,
              identity_verify:PropTypes.number.isRequired,                    
              identity_id: PropTypes.string.isRequired,
              driver_license: PropTypes.string.isRequired,
          })
      })
    }),
  };

  static defaultProps={
    registeredType: 'email',
    allConfirmed: false,
    loadingUpdatePassword: false,
    userAccountData: {
      loading: true,
      userAccountExt: {
          email: null,
          displayName: null,
          firstName: null,
          verification: {
              isEmailConfirmed: false,
              isIdNumberVerification: 0,
              isLicenseVerified: 0,
              isIdVerification: 0,
          },
          userProfileData:{
              isAgreementSigned:0,
              driver_license_verify:0,
              identity_verify:0,
              identity_id:null,
              driver_license:null
          }
      }
    },
  };
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: false,
      newPassword: false,
      confirmPassword: false,
      dateOfBirthData: {},
      countryCode: 'DO',
      country_code: 'DO',
      country: '+1',
      setNewPassword:'',
      setConfirmPassword:'',
      setOldPassword:null,
      // tetePasswordField:'leomadUyred)92'
    }
    this.handleChangeUpdateNewPassword = this.handleChangeUpdateNewPassword.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeField = this.handleChangeField.bind(this);
    this.handleInputEmpty = this.handleInputEmpty.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.onChangeNewPassword = this.onChangeNewPassword.bind(this);
    this.onChangeOldPassword = this.onChangeOldPassword.bind(this);
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
    this.documentDriverLicense = this.documentDriverLicense.bind(this);
    this.documentIdPassport = this.documentIdPassport.bind(this);
    this.renderCountryList = this.renderCountryList.bind(this);
  }

  componentDidMount() {
    const { change, initialValues,allConfirmed } = this.props;
    let loggedinEmail,province,driver_license,address,city,identity_id,identity_type;
    if (initialValues && initialValues.email) {
      
      loggedinEmail = initialValues.email;
      province = initialValues.userProfileData.province;
      driver_license = initialValues.userProfileData.driver_license;
      
      address = initialValues.userProfileData.address;
      city = initialValues.userProfileData.city;
      identity_id = initialValues.userProfileData.identity_id;
      identity_type = initialValues.userProfileData.identity_type;
    }
    change('loggedinEmail', loggedinEmail);
    change('province', province);
    change('driver_license', driver_license);
    
    change('address',address);
    change('city',city);
    change('identity_id',identity_id);
    change('identity_type',identity_type);
    if (initialValues && initialValues.userProfileData.country_code) {
      this.setState({
        country_code: initialValues.userProfileData.country_code
      });
    }
    if (initialValues && initialValues.countryName && initialValues.countryCode) {
      this.setState({
        countryCode: initialValues.countryName,
        country: initialValues.countryCode
      });
    }

  }

  UNSAFE_componentWillReceiveProps() {
    const { change, initialValues } = this.props;
    const { country, countryCode,country_code } = this.state;
    let loggedinEmail,province,driver_license,address,city,identity_id,identity_type;
    const { userAccountData, userAccountData: { userAccountExt } } = this.props;
    let setAllConfirmedInfo=false;
    
    if(userAccountExt){
      const { verification}= userAccountExt;
      if( verification.isEmailConfirmed == true && verification.isIdNumberVerification == 1 &&  verification.isLicenseVerified == 1 ){
        setAllConfirmedInfo= true;
        change('allConfirmed', setAllConfirmedInfo);
      }else{
        change('allConfirmed', setAllConfirmedInfo);
      }
    }
    if (initialValues && initialValues.email) {
      loggedinEmail = initialValues.email;
      province = initialValues.userProfileData.province;
      driver_license = initialValues.userProfileData.driver_license;
      
      address = initialValues.userProfileData.address;
      city = initialValues.userProfileData.city;
      identity_id = initialValues.userProfileData.identity_id;
      identity_type = initialValues.userProfileData.identity_type;

    }

    change('loggedinEmail', loggedinEmail);
   
    if (country_code) {
      change('country_code',country_code);
    }
    if (countryCode && country) {
      change('countryCode', countryCode);
      change('dialCode', country);
    }
  }

  UNSAFE_componentWillMount() {
    let now = new Date();
    let currentYear = now.getFullYear();
    let years = PopulateData.generateData(1900, currentYear, "desc");
    let days = PopulateData.generateData(1, 31);
    let months = PopulateData.generateData(0, 11);
    this.setState({
      dateOfBirthData: {
        years: years,
        months: months,
        days: days
      }
    });
  }

  renderCountryList({ input, label, meta: { touched, error }, children, className, disabled }) {
    const { formatMessage } = this.props.intl;
    return (
        <FormGroup className={cs.spaceBottom4}>
            <label>{label}</label>
            <CountryList input={input} className={className} disabled={disabled} />
            {touched && error && <span className={cs.errorMessage}>{formatMessage(error)}</span>}
        </FormGroup>
    );
  }
  renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className,isDisabled }) => {
    const { formatMessage } = this.props.intl;

    return (
      <FormGroup className={cs.spaceBottom4}>
        <label className={cs.spaceBottom8}>{label}</label>
        <FormControl
          {...input}
          className={className}
          componentClass="textarea"
          rows={5}
          placeholder={label}
          disabled={isDisabled}
        >
          {children}
        </FormControl>
        {touched && error && <span className={cs.errorMessage}>{formatMessage(error)}</span>}
      </FormGroup>
    )
  }

  renderFormControlPassword = ({ input, label, type, meta: { touched, error }, showPassword, placeholder,className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cx(cs.spaceBottom1, cs.positionRelative)}>
        <label>{label}</label>
        <FormControl {...input} type={showPassword ? input : type} 
          className={className}
          placeholder={placeholder} 
          maxLength={25} 
          autoComplete={"off"} />
        {type == 'password' && <span onClick={() => this.handleChangeField(input.name)} className={cx(cs.passwordIcon, 'passwordIconRTL')}>
          {showPassword ? <img src={ShowPassword} /> : <img src={HidePassword} />}
        </span>}
        {touched && error && <span className={cs.errorMessage}>{formatMessage(error)}</span>}
      </FormGroup>
    );
  }

  renderFormControl = ({ input, label, type, meta: { touched, error }, className, maxLength, isDisabled, inputPaddingClass, infoText, showToolTip }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cx(cs.spaceBottom4, inputPaddingClass)}>
        <label className={cs.spaceBottom8}>{label}
          {showToolTip &&
            <div className={cx(s.specialPriceIcon, 'specialPriceIconRTL')}>
              <span className={'svgImg'}>
                <img src={infoImage} className={cx(s.faqImage, 'specialpriceRtl')} />
              </span>
              <div className={cx(s.toolTip, s.toolTipRelativeSection, 'toolTipDarkMode', 'toolTipRelativeSectionRTL')}>
                {infoText}
              </div>
            </div>
          }
        </label>
        <FormControl {...input} placeholder={label} type={type} className={className} disabled={isDisabled} maxLength={maxLength} />
        {touched && error && <span className={cs.errorMessage}>{formatMessage(error)}</span>}
      </FormGroup>
    )
  }

  renderFormControlEmail = ({ input, label, type, meta: { touched, error }, className, disabled, inputPaddingClass }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cx(cs.spaceBottom4, inputPaddingClass)}>
        <label className={cs.spaceBottom8}>{label}</label>
        <FormControl {...input} placeholder={label} type={type} className={className} disabled={disabled} />
        {touched && error && <span className={cs.errorMessage}>{formatMessage(error)}</span>}
      </FormGroup>
    )
  }

  renderFormControlSelect = ({ input,label, meta: { touched, error }, children, className,isDisabled, inputPaddingClass,infoText,showToolTip }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cx(cs.spaceBottom4, inputPaddingClass)}>
       {label && <label className={cs.spaceBottom8}>{label}
          {showToolTip &&
            <div className={cx(s.specialPriceIcon, 'specialPriceIconRTL')}>
              <span className={'svgImg'}>
                <img src={infoImage} className={cx(s.faqImage, 'specialpriceRtl')} />
              </span>
              <div className={cx(s.toolTip, s.toolTipRelativeSection, 'toolTipDarkMode', 'toolTipRelativeSectionRTL')}>
                {infoText}
              </div>
            </div>
          }
        </label>
      }
        <FormControl disabled={isDisabled} componentClass="select" {...input} className={cx(className, 'selectBgImage')} >
          {children}
        </FormControl>
        {touched && error && <span className={cs.errorMessage}>{formatMessage(error)}</span>}
      </FormGroup>
    )
  }

  renderFormControlCurrency = ({ input, label, type, meta: { touched, error }, className, country, maxLength }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <InputGroup>
          <InputGroup.Addon className={s.addonStyle}>
            {country}
          </InputGroup.Addon>
          <FormControl {...input} placeholder={label} type={type} className={className} maxLength={maxLength} />
        </InputGroup>
        {touched && error && <span className={cs.errorMessage}>{formatMessage(error)}</span>}
      </div>
    )
  }


  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  onChangeNewPassword(event) {
    this.setState({ setNewPassword: event.target.value });
  }
  onChangeOldPassword(event) {
    this.setState({ setOldPassword: event.target.value });
  }
  onChangeConfirmPassword(event) {
    this.setState({ setConfirmPassword: event.target.value });
  }
  handleChangeField(fieldName) {
    this.setState({ [fieldName]: !this.state[fieldName] });
  }
  handleInputEmpty(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleCountryChange(e, selectedData) {
    this.setState({
      country: selectedData.dialCode,
      countryCode: selectedData.countryCode
    });

  }
  async handleChangeUpdateNewPassword(){
    const { oldPassword,newPassword,confirmPassword,setNewPassword,setConfirmPassword,setOldPassword}=this.state;
    const { updateUserPassword,registeredType }=this.props;
    this.setState({loadingUpdatePassword:true});
    const respUpdatePassword = await updateUserPassword(setOldPassword,setNewPassword,setConfirmPassword,registeredType);
    if( respUpdatePassword){
      this.setState({
        loadingUpdatePassword:false,
        setNewPassword:'',
        setConfirmPassword:'',
        setOldPassword:''
      });
    }
  }
  documentDriverLicense() {
    history.push('/document-verification/3/');
  }
  documentIdPassport() {
    history.push('/document-verification/2/'); 
  }
  render() {

    const { error, handleSubmit, submitting, dispatch, loadAccount, base, availableCurrencies, initialValues,values,registeredType} = this.props;
    const { formatMessage } = this.props.intl;
    const { siteSettingStatus } = this.props;
    const { dateOfBirthData } = this.state;
    const { country, countryCode,loadingUpdatePassword } = this.state;

    let idVerifiedStatus = 0,
      isLicenseVerifiedStatus = false,
      allConfirmedInfo = false;
    
    const { userAccountData, userAccountData: {loading, userAccountExt } } = this.props;

    
    if(userAccountExt){
      const { verification}= userAccountExt;

      if( verification.isEmailConfirmed == true && verification.isIdNumberVerification == 1 &&  verification.isLicenseVerified == 1 ){
        allConfirmedInfo= true;
      }
      
      idVerifiedStatus = userAccountExt.verification.isIdNumberVerification; //== 0 ? false : true;
      isLicenseVerifiedStatus = userAccountExt.verification.isLicenseVerified == 0 ? false : true;
    }
   
    const { setNewPassword,setConfirmPassword }= this.state;
    return (
      <div className={cx('inputFocusColor', cs.commonBorderSection, 'whiteBgColor')}>
        {error && <span className={cs.errorMessage}>{formatMessage(error)}</span>}
        <h2 className={cx(cs.commonTotalText, cs.fontWeightBold, cs.paddingBottom4)}>{formatMessage(messages.editYourProfile)}</h2>
        <Loader type={"pagefixed"} show={submitting} />
        { loading && <Loader type={"text"} /> }
        {!loading && userAccountExt !== undefined && userAccountExt !== null &&
          <Form onSubmit={handleSubmit(submit)}>
            
            {/* <Field name="allConfirmed" type="hidden" component={this.renderFormControl} /> */}
            <Field name="allConfirmed" type="hidden" component={CommonFormComponent} />
            <div className={s.displayGrid}>
              <Field name="firstName"
                type="text"
                component={this.renderFormControl}
                label={formatMessage(messages.firstName)}
                placeholder={formatMessage(messages.firstName)}
                className={!allConfirmedInfo ? cx(cs.formControlInput, 'commonInputPaddingRTL'):cx(s.iFieldLocked,cs.formControlInput, 'commonInputPaddingRTL')}
                inputPaddingClass={cx(s.paddingRight, 'RTLPaymentFirstNamePadding')}
                infoText={formatMessage(messages.lastNameInfo)}
                showToolTip
                maxLength={30}
                isDisabled={allConfirmedInfo}
              />
              <Field name="lastName"
                type="text"
                component={this.renderFormControl}
                label={formatMessage(messages.lastName)}
                placeholder={formatMessage(messages.lastName)}
                className={!allConfirmedInfo ? cx(cs.formControlInput, 'commonInputPaddingRTL'):cx(s.iFieldLocked,cs.formControlInput, 'commonInputPaddingRTL')}
                inputPaddingClass={cx(s.paddingLeft, 'RTLPaymentLastNamePadding')}
                maxLength={30}
                isDisabled={allConfirmedInfo}
              />
            </div>
            <div className={s.displayGrid}>
              <div>
                <Field name="gender" 
                  isDisabled={allConfirmedInfo}
                  className={cs.formControlSelect} 
                  label={formatMessage(messages.genderLabel)}
                  inputPaddingClass={cx(s.paddingRight, 'RTLPaymentFirstNamePadding')}
                 component={this.renderFormControlSelect}>
                  <option value="">{formatMessage(messages.gender)}</option>
                  <option value="Male">{formatMessage(messages.genderMale)}</option>
                  <option value="Female">{formatMessage(messages.genderFemale)}</option>
                  <option value="Other">{formatMessage(messages.genderOther)}</option>
                </Field>
              </div>

              <div className={cx(s.paddingLeft, 'RTLPaymentLastNamePadding')}>
                <label className={cs.spaceBottom8}>{formatMessage(messages.dateOfBirth)}</label>
                <div className={s.dateGridSection}>
                  <Field name="month" isDisabled={allConfirmedInfo} className={cx(cs.formControlSelect, s.editSelectPadding)}
                  component={this.renderFormControlSelect} >
                    <option value="">{formatMessage(messages.month)}</option>
                    {
                      dateOfBirthData && dateOfBirthData.months && dateOfBirthData.months.length > 0 && dateOfBirthData.months.map((item, key) => {
                        return (
                          <option key={key} value={item}>{item + 1}</option>
                        )
                      })
                    }
                  </Field>
                  <Field name="day" isDisabled={allConfirmedInfo} className={cx(cs.formControlSelect, s.editSelectPadding)}
                  inputPaddingClass={s.datePadding} component={this.renderFormControlSelect} >
                    <option value="">{formatMessage(messages.transferDate)}</option>
                    {
                      dateOfBirthData && dateOfBirthData.days && dateOfBirthData.days.length > 0 && dateOfBirthData.days.map((item, key) => {
                        return (
                          <option key={key} value={item}>{item}</option>
                        )
                      })
                    }
                  </Field>
                  <Field name="year" className={cx(cs.formControlSelect, s.editSelectPadding)} component={this.renderFormControlSelect} isDisabled={allConfirmedInfo} >
                    <option value="">{formatMessage(messages.year)}</option>
                    {
                      dateOfBirthData && dateOfBirthData.years && dateOfBirthData.years.length > 0 && dateOfBirthData.years.map((item, key) => {
                        return (
                          <option key={key} value={item}>{item}</option>
                        )
                      })
                    }
                  </Field>
                </div>
              </div>
            </div>
            
            <div className={s.displayGrid}>
              <Field name="email"
                type="text"
                component={this.renderFormControlEmail}
                label={formatMessage(messages.emailLabel)}
                className={cx(cs.formControlInput, s.editProfileEmail, 'commonInputPaddingRTL')}
                disabled={true}
                inputPaddingClass={cx(s.paddingRight, 'RTLPaymentFirstNamePadding')}
              />

              <div>
                <label className={cs.spaceBottom8}>{formatMessage(messages.chooseLanguage)}</label>
                <Field name="preferredLanguage" className={cs.formControlSelect} inputPaddingClass={cx(s.paddingRight, 'RTLPaymentFirstNamePadding')} component={this.renderFormControlSelect}>
                  <option value="en-US">{"English"}</option>
                  <option value="es">{"Español"}</option>
                </Field>
              </div>
            </div>

            <div className={s.contentUpdatePassword}>
              

              <h3 className={cx(cs.commonTotalText, cs.spaceBottom4)}><FormattedMessage {...messages.changePassword} /></h3>
              
                {error && <strong>{error}</strong>}
                
                {
                  initialValues && registeredType === 'email' && <Field
                    name="oldPassword"
                    type="password"
                    className={cx(cs.formControlInput,cs.spaceBottom2, 'passwordInputRTL', s.formControlpassWord)}
                    component={this.renderFormControlPassword}
                    label={formatMessage(messages.oldPassword)}
                    showPassword={this.state.oldPassword}
                    onChange={(e) => this.onChangeOldPassword(e)} 
                    placeholder={formatMessage(messages.enterOldPassword)}
                  />
                }
                <div className={cx(s.displayGrid,s.paddingTopGrid)}>
                  <div className={cx(s.paddingField)}>
                  <Field name="newPassword" 
                    type="password" 
                    component={this.renderFormControlPassword}
                    
                    onChange={(e) => this.onChangeNewPassword(e)} 
                    className={cx(cs.formControlInput,s.paddingRight, 'passwordInputRTL', s.formControlpassWord)} 
                    showPassword={this.state.newPassword} 
                    label={formatMessage(messages.newPassword)} 
                    placeholder={formatMessage(messages.newPassword)} />
                    <PasswordStrengthBar className={cx(s.fieldPassProgressBar)} password={setNewPassword} />
                  </div>
                  <div className={cx(s.paddingField)}>
                    <Field name="confirmPassword"
                    type="password" 
                    onChange={(e) => this.onChangeConfirmPassword(e)} 
                    className={cx(cs.formControlInput, 'passwordInputRTL', s.formControlpassWord)}
                    component={this.renderFormControlPassword} 
                    showPassword={this.state.confirmPassword} 
                    label={formatMessage(messages.confirmNewPassword)} placeholder={formatMessage(messages.confirmNewPassword)} />
                    <PasswordStrengthBar className={cx(s.fieldPassProgressBar)} password={setConfirmPassword} />
                   </div>
                </div>
                <div className={cx(cs.textAlignRight, 'textAlignLeftRTL')}>
                  <Button className={cs.btnPrimary} type="button" onClick={this.handleChangeUpdateNewPassword} disabled={loadingUpdatePassword}>
                    <FormattedMessage {...messages.updatePassword} />
                  </Button>
                </div>
            </div>

            <div className={cx(cs.contentSplitBorderTop)}></div>
            {!idVerifiedStatus && <p onClick={this.documentIdPassport} className={cx(cs.isNotVerified)}>{formatMessage(messages.userIDNotVerified)}</p> }
            <div className={!idVerifiedStatus ? cx(s.displayGrid,cs.isFieldNoVerified): cx(s.displayGrid)}>
              <div>
                <Field 
                name="identity_type" 
                className={!idVerifiedStatus ? cx(cs.formControlSelect):cx(s.iFieldLocked,cs.formControlSelect)}
                label={formatMessage(messages.userIdentifyIDType)}
                inputPaddingClass={cx(s.paddingRight, 'RTLPaymentFirstNamePadding')} 
                component={this.renderFormControlSelect}
                infoText={formatMessage(messages.typeNumberInfo)}
                showToolTip
                isDisabled={!idVerifiedStatus ? false : true}
                >
                  {/* <option value="1">{formatMessage(messages.userGovermentID)}</option> */}
                  <option value="2">{formatMessage(messages.userPassportID)}</option>
                  <option value="3">{formatMessage(messages.userCedula)}</option>
                </Field>
              </div>
            
              <div className={cx(s.paddingLeft, 'RTLPaymentLastNamePadding')}>
                <Field name="identity_id"
                  type="text"
                  component={this.renderFormControl}
                  label={formatMessage(messages.userIdentifyIDNumber)}
                  className={!idVerifiedStatus ? cx(cs.formControlInput, 'commonInputPaddingRTL') : cx(s.iFieldLocked,cs.formControlInput, 'commonInputPaddingRTL')}
                  
                  inputPaddingClass={cx(s.paddingRight, 'RTLPaymentFirstNamePadding')}
                  infoText={formatMessage(messages.idNumberInfo)}
                  showToolTip
                  isDisabled={!idVerifiedStatus ? false : true}
                />
              </div>
            </div>
            {!isLicenseVerifiedStatus && <p onClick={this.documentDriverLicense} className={cx(cs.isNotVerified)}>{formatMessage(messages.userLicenseNotVerified)}</p> }
            <div className={!isLicenseVerifiedStatus ? cx(s.displayGrid,cs.isFieldNoVerified): cx(s.displayGrid)}>
              <Field name="driver_license"
                type="text"
                onChange={this.handleInputEmpty}
                component={this.renderFormControl}
                label={formatMessage(messages.userDriverLicense)}
                className={!isLicenseVerifiedStatus ? cx(cs.formControlInput, 'commonInputPaddingRTL'):cx(s.iFieldLocked,cs.formControlInput, 'commonInputPaddingRTL')}
                inputPaddingClass={cx(s.paddingRight, 'RTLPaymentFirstNamePadding')}
                infoText={formatMessage(messages.driverLicenseInfo)}
                showToolTip
                isDisabled={!isLicenseVerifiedStatus ? false : true}
              />
              <Field
                  name="country_code"
                  component={this.renderCountryList}
                  label={formatMessage(messages.country)}
                  className={cs.formControlSelect}
                  disabled={allConfirmedInfo}
              />
            </div>
            
            <div className={s.displayGrid}>
              <Field name="province"
                type="text"
                component={this.renderFormControl}
                label={formatMessage(messages.userProvince)}
                className={cx(cs.formControlInput, 'commonInputPaddingRTL')}
                inputPaddingClass={cx(s.paddingRight, 'RTLPaymentFirstNamePadding')}
                isDisabled={allConfirmedInfo}
              />
              <Field name="city"
                type="text"
                component={this.renderFormControl}
                label={formatMessage(messages.userCity)}
                className={cx(cs.formControlInput, 'commonInputPaddingRTL')}
                inputPaddingClass={cx(s.paddingRight, 'RTLPaymentFirstNamePadding')}
                isDisabled={allConfirmedInfo}
              />
            </div>
            
            <Field name="address"
              component={this.renderFormControlTextArea}
              label={formatMessage(messages.userAddress)}
              className={cx(cs.formControlInput, 'commonInputPaddingRTL')}
              isDisabled={allConfirmedInfo}
            />
            <div className={cx(cs.contentSplitBorderBottom)}></div>
            <Field name="info"
              component={this.renderFormControlTextArea}
              label={formatMessage(messages.info)}
              className={cx(cs.formControlInput, 'commonInputPaddingRTL')}
            />
            <p className={cx(cs.commonMediumText, s.paddingBottom4)}>{formatMessage(messages.DescribeProfileInfo)}</p>
              
            
            <div className={cx(cs.textAlignRight, 'textAlignLeftRTL')}>
              <Button className={cx(cs.btnPrimary, cs.btnMedium)} type="submit" disabled={submitting}>
                {formatMessage(messages.save)}
              </Button>
            </div>
          </Form>
        }
      </div>
    )
  }

}
EditProfileForm = reduxForm({
  form: 'EditProfileForm', // a unique name for this form
  validate,
})(EditProfileForm);
const selector = formValueSelector('EditProfileForm');

const mapState = (state) => ({
  initialValues: state.account.data,
  availableCurrencies: state.currency.availableCurrencies,
  base: state.currency.base,
  siteSettingStatus: state.siteSettings.data,
  registeredType: state.account.data.userData.type,
});
const mapDispatch = {
  change,
  updateUserPassword
};

export default compose(
  injectIntl,
  withStyles(s, cs),
  connect(mapState, mapDispatch),
  graphql(gql`
      query getCountries {
          getCountries{
              id
              countryCode
              countryName
              isEnable
              dialCode
          }
      }
  `, {
    options: {
      ssr: false,
      fetchPolicy: 'network-only'
    }
  }),
  graphql(userAccountQuery, {
    name: 'userAccountData',
    options: {
    ssr: false,
    fetchPolicy: 'network-only'
    }
  }),
)(EditProfileForm);