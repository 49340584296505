exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MediaGalleryUpload-fileContent-1gycK{\n    margin-top: 20px;\n    font-weight: 700;\n    font-size: 20px;\n}", ""]);

// exports
exports.locals = {
	"fileContent": "MediaGalleryUpload-fileContent-1gycK"
};