// General
import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql, gql, compose } from 'react-apollo';
import moment from 'moment';

// Redux
import { connect } from "react-redux";

// Translation
import { injectIntl, FormattedMessage } from "react-intl";

// query
import userAccountQuery from './userAccountQuery.graphql';
// import getListingExtQuery from './getListingExtQuery.graphql';

// Locale
import messages from "../../locale/messages";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./ShowNotification.css";
import cs from "../commonStyle.css";

// import { resendEmailVerification } from '../../actions/manageUserVerification';
import history from '../../core/history';
import { FaBullseye } from "react-icons/fa";

class ShowNotification extends Component {
  static propTypes = {
    isHasError:PropTypes.bool,
    isShowing: PropTypes.bool,
    listingID: PropTypes.number,
    listingPlateDoc: PropTypes.bool,
    showHeader: PropTypes.bool,
    showPhoneNumber: PropTypes.bool,
    showEmailConfirmed: PropTypes.bool,
    showSubTitle: PropTypes.bool,
    showSimpleHeader: PropTypes.bool,
    showAgreement: PropTypes.bool,
    showVehicleDoc: PropTypes.bool,
    showIDIdentification: PropTypes.bool,
    showDocVerification: PropTypes.bool,
    showDriverLicense: PropTypes.bool,
    userAccountData: PropTypes.shape({
      loading: PropTypes.bool,
      userAccountExt: PropTypes.shape({
          userId: PropTypes.string.isRequired,
          email: PropTypes.string.isRequired,
          displayName: PropTypes.string.isRequired,
          firstName: PropTypes.string.isRequired,
          phoneNumber: PropTypes.string,
          verification: PropTypes.shape({
              isEmailConfirmed: PropTypes.bool.isRequired,
              isIdNumberVerification: PropTypes.number.isRequired,
              isLicenseVerified: PropTypes.number.isRequired,
              isIdVerification: PropTypes.number,
              isPhotoVerified: PropTypes.number,
          }),
          userProfileData: PropTypes.shape({
              isAgreementSigned: PropTypes.number,
              // isVerifiedPlateDoc: PropTypes.number.isRequired,
              isAgreementStatus: PropTypes.number,
              driver_license_verify:PropTypes.number,
              identity_verify:PropTypes.number,                    
              identity_id: PropTypes.string,
              driver_license: PropTypes.string,
          }),
          agreementData:PropTypes.array.isRequired
      })
    }),
    ShowListingExtData: PropTypes.shape({
      loading: PropTypes.bool,
      ShowListingExt: PropTypes.shape({        
          isVerifiedPlateDoc: PropTypes.number.isRequired,
          isVerifiedPhotos:PropTypes.number.isRequired,
        })
    }),
  };
  static defaultProps = {
    isShowing: false,
    listingID: 0,
    listingPlateDoc: false,
    showHeader: false,
    showPhoneNumber: false,
    showEmailConfirmed: false,
    showSubTitle: false,
    showSimpleHeader: false,
    showAgreement: false,
    showVehicleDoc: false,
    showIDIdentification: false,
    showDocVerification: false,
    showDriverLicense: false,
    userAccountData: {
      loading: true,
      userAccountExt: {
          userId:null,
          email: null,
          displayName: null,
          firstName: null,
          phoneNumber: null,
          verification: {
              isEmailConfirmed: false,
              isIdNumberVerification: 0,
              isLicenseVerified: 0,
              isIdVerification: 0,
              isPhotoVerified: 0,
          },
          userProfileData:{
              isAgreementSigned:0,
              isAgreementStatus:0,
              isVerifiedPlateDoc:0,
              driver_license_verify:0,
              identity_verify:0,
              identity_id:null,
              driver_license:null
          },
          agreementData:[]
      }
    },
    ShowListingExtData:{
      loading: false,
      ShowListingExt:{
          isVerifiedPlateDoc:0,
          isVerifiedPhotos:0,
        }
    },
  };
  constructor(props) {
    super(props);
    this.state={
      isHasError:true}
    ;
    
    this.goToVerifyEmail = this.goToVerifyEmail.bind(this);
    this.documentVerificationID = this.documentVerificationID.bind(this);
    this.documentAgreement = this.documentAgreement.bind(this);
    this.documentDriverLicense = this.documentDriverLicense.bind(this);
    this.documentVerificationApp = this.documentVerificationApp.bind(this);
    this.documentGoPhoneNumber = this.documentGoPhoneNumber.bind(this);
    this.documentVehiclePlate = this.documentVehiclePlate.bind(this);
    // this.doChangeShowFields = this.doChangeShowFields.bind(this);
  }


  goToVerifyEmail(){
    history.push('/user/verification');
  }

  documentGoPhoneNumber() {
    history.push('/user/personal/');
  }
  
  documentVerificationApp() {
    history.push('/document-verification/1/');
  }
  
  documentVerificationID() {
    history.push('/document-verification/2/');
  }
  documentVehiclePlate() {
    const {listingID}= this.props;
    history.push('/become-a-owner/'+listingID+'/photos');
  }
  
  documentAgreement() {
    history.push('/agreement/view/');
  }
  documentDriverLicense() {
    history.push('/document-verification/3/');
  }
  render() {
    // let handleToUpdate = this.props.handleToUpdate;
    const { handleToUpdate}=this.props;

    const { account,isShowing,showHeader,showSubTitle,showSimpleHeader,showAgreement,showVehicleDoc,showIDIdentification,showDriverLicense,showPhoneNumber,showEmailConfirmed,showDocVerification,listingID,listingPlateDoc } = this.props;
    const {isHasError}=this.props;
    const { formatMessage } = this.props.intl;
    const { userAccountData, userAccountData: { userAccountExt } } = this.props;

    let agreementStatus=0,agreementDealerSigned='',agreementAccepted='',agreementNum='';
    let showMissedField=true,isNotificationDriverLicense=true,isNotificationIDPassport=true,isPhoneNumberReq=true,isEmailConfirmedN=true,isDocVerification=true,isNotificationPlateDocs=true,isPhotoNotVerified=true;
    
    let agreementSign = false,agreementStatusMessageType='signup';
    
    if(userAccountExt){
        const fVerification = userAccountExt.verification;
        const fAgreementDT = userAccountExt.agreementData[0];
        const fProfileDT = userAccountExt.userProfileData;
        agreementNum = fAgreementDT.code !=null ? fAgreementDT.code.toUpperCase() : '';
        agreementAccepted = fAgreementDT.contract_date_signed !=null ? moment(fAgreementDT.contract_date_signed).format('DD-MM-YYYY hh:mm A').toString() : '';
        agreementDealerSigned = fAgreementDT.delear_signup_date !=null ? moment(fAgreementDT.delear_signup_date).format('DD-MM-YYYY hh:mm A').toString() : '';
        agreementStatus = fProfileDT.isAgreementStatus;
        // const 
        if( fProfileDT.isAgreementSigned == 1 && fProfileDT.isAgreementStatus == 1){
          agreementStatusMessageType='review'; agreementSign = true;
        }

        if(fVerification.isPhotoVerified == 1){ 
          isPhotoNotVerified=false;
        } 
        
        if(fVerification.isEmailConfirmed == true){ 
          isEmailConfirmedN=false;
        } 
        if(fVerification.isIdNumberVerification == 1 && fProfileDT.identity_id.trim() != ''){ isNotificationIDPassport=false; } 
        

        if(fVerification.isLicenseVerified ==1 && fProfileDT.driver_license !=null 
        ){ 
          isNotificationDriverLicense=false;
        }
        
        this.props.handleShowError; 
        if(fVerification.isIdVerification ==1){  isDocVerification=false; }
        // if(userAccountExt.phoneNumber != null){ isPhoneNumberReq=false; } 
        isPhoneNumberReq=false;
        if( isEmailConfirmedN == false &&
          isNotificationIDPassport == false &&
          isNotificationDriverLicense == false &&
          isDocVerification == false &&
          isPhotoNotVerified == false &&
          isPhoneNumberReq == false && agreementSign == false && listingPlateDoc == false){
            showMissedField=false;
            this.props.handleHideError;
            
          }
    }

    return (
      <div>
        {showMissedField == true && (
          <div className={cx(s.containerAlertPublish)}>
            {showHeader == true && <h3 className={cx(s.mbMargin, s.headerText)}>
              <FormattedMessage {...messages.listingAlertPublish} />
              </h3>
            }
            {showSubTitle == true && <h4 className={cx(s.mbMargin, s.subtitleText)}><FormattedMessage {...messages.listinSubTitlePublish} />
            </h4>}
            {showSimpleHeader == true && <h4 className={cx(s.mbMargin, s.simpleHeader)}><FormattedMessage {...messages.listinSubTitlePublish} />
            </h4>}

            {isPhotoNotVerified == true && <p onClick={this.goToVerifyEmail}><FormattedMessage {...messages.missingPhotoProfile} /> </p> }

            {agreementSign && showAgreement == true && agreementStatusMessageType == 'signup' && <p onClick={this.documentAgreement}><FormattedMessage {...messages.missingAgreement} /> </p> }
            
            {agreementSign && showAgreement == true && agreementStatusMessageType == 'review' && <p onClick={this.documentAgreement}><FormattedMessage {...messages.agreementInfoReview} /> </p> }
            
            {/* {isDocVerification && showDocVerification == true && <p onClick={this.documentVerificationApp}><FormattedMessage {...messages.missingIDDocVerification} /> </p> } */}
            
            {isNotificationIDPassport && showIDIdentification == true && <p onClick={this.documentVerificationID}><FormattedMessage {...messages.missingIDPassport} /> </p> }
            
            {isNotificationDriverLicense && showDriverLicense == true && <p onClick={this.documentDriverLicense}><FormattedMessage {...messages.missingDriverLicense} /> </p> }
            
            {isPhoneNumberReq && showPhoneNumber == true && <p onClick={this.documentGoPhoneNumber}><FormattedMessage {...messages.missingPhoneNumber} /> </p> }

            {isEmailConfirmedN && showEmailConfirmed == true && <p onClick={this.goToVerifyEmail}><FormattedMessage {...messages.needConfirmEmail} /> </p> }
            
            {listingPlateDoc && showVehicleDoc == true && <p onClick={this.documentVehiclePlate}><FormattedMessage {...messages.missingCarDocUpload} /></p>}
          </div>
        )}
      </div>
    );
  }
}
const mapState = (state) => ({
  account: state.account.data,
});
const mapDispatch = {
  // resendEmailVerification
};
export default compose(
  injectIntl,
  withStyles(s, cs),
  connect(mapState, mapDispatch),
  graphql(userAccountQuery, {
      name: 'userAccountData',
      options: {
      ssr: false,
      fetchPolicy: 'network-only'
      }
  }),
)(ShowNotification);
