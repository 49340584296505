import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
//styles
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './AgreementContainer.css';
// Component
import AgreementsPreview from '../../components/AgreementsPreview/AgreementsPreview';


class AgreementContainer extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      searchKey: ''
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { type } = nextProps
    if (type !== this.props.type)this.setState({currentPage:1})
  }

  setStateVariable = (variables) => {
    this.setState(variables)
  }

  render() {
    const { type } = this.props;
    const { currentPage, searchKey } = this.state;
    return (
      <AgreementsPreview type={type} /> 
    );
  }
}

export default compose(
  withStyles(s),
)(AgreementContainer);