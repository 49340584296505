import React from 'react';
import UserLayout from '../../components/Layout/UserLayout';
import AgreementContainer from './AgreementContainer';
import NotFound from '../notFound/NotFound';
import Layout from '../../components/Layout/Layout';

const title = 'Agreement';

export default async function action({ store, params }) {

  // From Redux Store
  const isAuthenticated = store.getState().runtime.isAuthenticated;
  const type = params.type;
  if (!isAuthenticated) {
    return { redirect: '/login' };
  }
  if ( type === 'view') {
    // if (type === 'overview' || type === 'info' || type === 'view') {
    return {
      title,
      component: <UserLayout ><AgreementContainer  type={type} /></UserLayout>,
    }
  }
  return {
    title,
    component: <Layout><NotFound title={title} /></Layout>,
    status: 404
  }

};


// export default async function action({ store }) {

//     // From Redux Store
//     let isAuthenticated = store.getState().runtime.isAuthenticated;

//     if (!isAuthenticated) {
//       return { redirect: '/login' };
//     }

//     await store.dispatch(setSiteSettings());

//     return {
//       title,
//       component: <UserLayout><EditViewAgreement title={title} /></UserLayout>,
//     };
//   };
