// Redux Form
import { SubmissionError } from 'redux-form';

// Fetch request
import fetch from '../../core/fetch';

// Language
import messages from '../../locale/messages';

// Redux
import { setRuntimeVariable } from '../../actions/runtime';
import { loadAccount } from '../../actions/account';
import { closeSignupModal } from '../../actions/modalActions';

// Helper
import { createSetViaFirmaOwner } from '../../core/contracts/contractsHelper';

// Send Email
import { sendEmail } from '../../core/email/sendEmail';
import showToaster from '../../helpers/toasterMessages/showToaster'

async function submit(values, dispatch) {
  
  const loggedinEmail = values.loggedinEmail;
  const loggedinUser = values.loggedinUser;
  const agreementId = values.agreementId;
  if (!values.iAgreeTerms) {
      showToaster({ messageId: 'checkAgreementTerms', toasterType: 'error' })
      return false;
    }

  const query = `query (
    $isAgreementSigned:Int,
    $isAgreementStatus:Int,
    
  ) {
    userEditProfileAgreement (
      isAgreementSigned:$isAgreementSigned,
      isAgreementStatus:$isAgreementStatus,
        
      ) {
        status
        profileid
      }
    }`;
  const queryUpdate = `query (
    $agreementId:Int,
    $fieldsUpdate:String,
    $updateProfile:Int,
    
  ) {
    updateAgreementUser (
      agreementId:$agreementId,
      fieldsUpdate:$fieldsUpdate,
      updateProfile:$updateProfile,
        
      ) {
        status
        profileid
      }
    }`;


  
  
  const params = {
    isAgreementSigned: 1,
    isAgreementStatus:1
  };

  const resp = await fetch('/graphql', {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: query,
      variables: params
    }),
    credentials: 'include'
  });

  const { data } = await resp.json();

  if (data.userEditProfileAgreement.status == "success") {
    
    
    const resVF = await createSetViaFirmaOwner(data.userEditProfileAgreement.profileid);
    if(resVF){
      await dispatch(loadAccount());
      showToaster({ messageId: 'updateProfile', toasterType: 'success' })
      const {status,code,messages,links}= resVF;
      
      if(status == 'RECEIVED'){
        let content = {token: links[0].link,email: loggedinEmail,name: loggedinUser};
        const paramsUpdAgreement = {
          agreementId: agreementId,
          fieldsUpdate:`{"vfSetCode":"${code}","vfSignerLink":"${links[0].link}", "vfMessageCode":"${messages[0].code}", "vfSignerToken":"${links[0].token}", "vfSetStatus":"${status}"}`,
          updateProfile:0,
        };
        const respUpdate = await fetch('/graphql', {
          method: 'post',
          headers: {Accept: 'application/json','Content-Type': 'application/json'},
          body: JSON.stringify({ query: queryUpdate, variables: paramsUpdAgreement}),
          credentials: 'include'
        });
        const dataUpd  = await respUpdate.json();
        // console.log('dataUpd',dataUpd);
        if (dataUpd.data.updateAgreementUser.status == "success") {
          await sendEmail(loggedinEmail,'ownerAgreement',content);
          // console.log('resVF',resVF);
         window.location = links[0].link; 
        }
      }
    }
    
  } else if (data.userEditProfileAgreement.status == "email") {
    showToaster({ messageId: 'emailAlreadyExist', toasterType: 'error' })
  } else if (data.userEditProfileAgreement.status == "notLoggedIn") {
    dispatch(setRuntimeVariable({
      name: 'isAuthenticated',
      value: false,
    }));

    showToaster({ messageId: 'loginUser', toasterType: 'error' })
    //throw new SubmissionError({ _error: messages.notLoggedIn });
  } else {
    // throw new SubmissionError({ _error: messages.somethingWentWrong });

    showToaster({ messageId: 'reloadPage', toasterType: 'error' })
  }

}

export default submit;
