import React from 'react';
import PropTypes from 'prop-types';
import { graphql, gql, compose } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, reduxForm,change, getFormSyncErrors } from 'redux-form';
// Style
import {
    Button,
    Col,
    Tab,
    Tabs,
    FormGroup,
    FormControl
} from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import submit from './submit';
import validate from './validate';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './AgreementsPreview.css';
import cs from '../../components/commonStyle.css'
import cx from 'classnames';

// query
import userAccountQuery from './userAccountQuery.graphql';

// Component
import Loader from '../../components/Loader';
import CustomCheckbox from '../CustomCheckbox';
import ShowNotification from '../ShowNotification/ShowNotification';
import CommonFormComponent from '../CommonField/CommonFormComponent';

// Locale
import messages from '../../locale/messages';
// import debounce from '../../helpers/debounce';

// Images
import arrow from '/public/siteImages/whiteArrow.svg';
// import { Input } from '@material-ui/core';

const onSubmitFail = (errors) => {
    if (errors) {
      let section = Object.keys(errors);
      if (section && section.length > 0)
        document.getElementById(section[0]).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }
  };
class AgreementsPreview extends React.Component {
    static propTypes = {
        formatMessage: PropTypes.func,
        isAgreementSigned: PropTypes.bool,
        account: PropTypes.shape({
			userId: PropTypes.string.isRequired,
			email: PropTypes.string.isRequired,
			picture: PropTypes.string,
			displayName: PropTypes.string.isRequired,
			firstName: PropTypes.string.isRequired,
			verification: PropTypes.shape({
				isEmailConfirmed: PropTypes.bool.isRequired
			})
		}),
        userAccountData: PropTypes.shape({
            loading: PropTypes.bool,
            userAccountExt: PropTypes.shape({
                userId: PropTypes.string.isRequired,
                email: PropTypes.string.isRequired,
                displayName: PropTypes.string.isRequired,
                firstName: PropTypes.string.isRequired,
                phoneNumber: PropTypes.string,
                verification: PropTypes.shape({
                    isEmailConfirmed: PropTypes.bool.isRequired,
                    isIdNumberVerification: PropTypes.number.isRequired,
                    isLicenseVerified: PropTypes.number.isRequired,
                    isIdVerification: PropTypes.number,
                }),
                userProfileData: PropTypes.shape({
                    isAgreementSigned: PropTypes.number.isRequired,
                    isAgreementStatus: PropTypes.number.isRequired,
                    driver_license_verify:PropTypes.number.isRequired,
                    identity_verify:PropTypes.number.isRequired,                    
                    identity_id: PropTypes.string.isRequired,
                    driver_license: PropTypes.string.isRequired,
                }),
                agreementData:PropTypes.array.isRequired
            })
        }),
        type: PropTypes.string.isRequired,
        loading: PropTypes.bool.isRequired,
        isLoaded: PropTypes.bool.isRequired,
    };  
    static defaultProps = {
        isLoaded:false,
        isAgreementSigned:false,
		account: {
			email: null,
			displayName: null,
			firstName: null,
			picture: null,
			verification: {
				isEmailConfirmed: false
			}
		},
        userAccountData: {
            loading: true,
            userAccountExt: {
                userId:null,
                email: null,
                displayName: null,
                firstName: null,
                phoneNumber: null,
                verification: {
                    isEmailConfirmed: false,
                    isIdNumberVerification: 0,
                    isLicenseVerified: 0,
                    isIdVerification: 0,
                },
                userProfileData:{
                    isAgreementSigned:0,
                    isAgreementStatus:0,
                    driver_license_verify:0,
                    identity_verify:0,
                    identity_id:null,
                    driver_license:null
                },
                agreementData:[]
            }
        },
	};

    constructor(props) {
        super(props);
        this.state={
            isHavingError:false,
            emailTo:null,
            btnCondictionDisabled:true
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleShowError = this.handleShowError.bind(this);
        this.handleHideError = this.handleHideError.bind(this);
    }
    // componentDidMount() {
    //     const { change, account } = this.props;
    //     let loggedinEmail;
    //     if (account && account.email) {
    //       loggedinEmail = account.email;
    //     }
    //     change('loggedinEmail', loggedinEmail);
    
        
    
    //   }
    UNSAFE_componentWillReceiveProps() {
        const { change, account } = this.props;
        const { userAccountData, userAccountData: { userAccountExt } } = this.props;
        let loggedinEmail,loggedinUser,agreementId;
        
        if (account && account.email) {
            loggedinEmail = account.email;
            loggedinUser = account.displayName;
            change('loggedinEmail', loggedinEmail);
            change('loggedinUser', loggedinUser);
        }
        if (userAccountExt) {
            agreementId = userAccountExt.agreementData[0].id;
            change('agreementId', agreementId);
        }
    }
    handleSearch = (searchKey) => {
        const { data: { refetch }, setStateVariable } = this.props;
        let variables = { searchKey, currentPage: 1 };
        setStateVariable(variables)
        refetch(variables)
    }
    
    handleShowError(){
        this.setState({isHavingError: true});
    }
      
    handleHideError(){
        this.setState({isHavingError: false});
    }
    handleChange(e) {
        const { isHavingError }=this.state;
        // console.log('event',e);
        if(isHavingError == false){
            this.setState({ btnCondictionDisabled: e ==true ? false: true }); 
        }
        // this.props.isAgreementSigned=e;
        // this.setState({ isAgreementSigned: e });
      }
      
    checkboxGroup = ({ input, meta: { touched, error }, children, className, selectClass, name }) => {
        return (
          <div className={cx(s.checkBoxGrid, cs.paddingBottom4)}>
            <CustomCheckbox
              name={name}
              className={cx('icheckbox_square-green',s.icheckboxWithBorder)}
              checked={input.value == true}
              onChange={event => {
                this.handleChange(event); return input.onChange(event);
              }}
            />
            <p className={cx(cs.commonMediumText, cs.fontWeightNormal, s.checkBoxText, 'signUpCheckBoxTextRTl')}>
              <FormattedMessage {...messages.iAgreeContract} />
              {/* <a href={"/privacy"} target={'blank'} className={cx(cs.siteLinkColor, cs.textTransformLowerCase)}>&nbsp;<FormattedMessage {...messages.termsOfService} />&nbsp;</a>
              <FormattedMessage {...messages.and} />
              <span>&nbsp;<a href={"/privacy"} target={'blank'} className={cx(cs.siteLinkColor, cs.textTransformLowerCase)}><FormattedMessage {...messages.privacyPolicy} /></a>&nbsp;</span> */}
            </p>
          </div>
        )
    }
    render() {
        
        const { isLoaded , error, handleSubmit, submitting,type, account } = this.props;
        const { formatMessage } = this.props.intl;
        const { isHavingError,btnCondictionDisabled } = this.state;
        console.log('account',account);
        
        let userIdFile='',showDocFile=true;
        const { userAccountData, userAccountData: { loading,userAccountExt } } = this.props;

        let agreementStatus=0,agreementDealerSigned='',agreementAccepted='',agreementNum='';
        let showMissedField=false,isNotificationDriverLicense=false,isNotificationIDPassport=false,isPhoneNumberReq=false,isEmailConfirmedN=false,isDocVerification=false;
        let agreementSign = false,isUnderReview=false;
         
        if(userAccountExt){
            console.log('isUnderReview',isUnderReview);
            console.log('userAccountExt',userAccountExt);
            agreementNum = userAccountExt.agreementData[0].code !=null ? userAccountExt.agreementData[0].code.toUpperCase() : '';
            
            agreementAccepted = userAccountExt.agreementData[0].contract_date_signed !=null ? moment(userAccountExt.agreementData[0].contract_date_signed).format('DD-MM-YYYY hh:mm A').toString() : '';
            
            agreementDealerSigned = userAccountExt.agreementData[0].delear_signup_date !=null ? moment(userAccountExt.agreementData[0].delear_signup_date).format('DD-MM-YYYY hh:mm A').toString() : '';
            
            agreementStatus = userAccountExt.userProfileData.isAgreementStatus;
            agreementSign = userAccountExt.userProfileData.isAgreementSigned == 0 ? false : true;
            userIdFile = userAccountExt.userId; showDocFile=true;
            if( userAccountExt.userProfileData.isAgreementSigned == 1 && userAccountExt.userProfileData.isAgreementStatus == 1){
                // agreementSign=true; 
                isUnderReview=true;
            }
            if(userAccountExt.verification.isEmailConfirmed == false){ isEmailConfirmedN=true; showMissedField=true;} 
            if(userAccountExt.verification.isIdNumberVerification == 0 || userAccountExt.userProfileData.identity_id.trim() == ''){ isNotificationIDPassport=true; showMissedField=true;} 
            if(userAccountExt.verification.isLicenseVerified ==0 || userAccountExt.userProfileData.driver_license ==null || userAccountExt.userProfileData.driver_license.trim() == ''){ isNotificationDriverLicense=true; showMissedField=true; }
            if(userAccountExt.verification.isIdVerification ==0){ 
                showMissedField=true; isDocVerification=true;
             }
            if(userAccountExt.phoneNumber == null){ showMissedField=true;isPhoneNumberReq=true; }
            console.log('agreementStatus',agreementStatus);
            console.log('agreementSign',agreementSign);
            console.log('isUnderReview',isUnderReview);
        }
        return (
            
            <Col xs={12} sm={12} md={12} lg={12} className={cs.spaceTop6}>
                {loading  && <Loader type={"text"} />}
                {!loading && <div className={cx(cs.tripsReservationPanel, 'youcarsBg', 'bgBlack')}>
                <h2 className={cx(cs.commonTotalText, cs.fontWeightBold, cs.paddingBottom4)}>{formatMessage(messages.overviewAgreement)}</h2>
                {account && account.userProfileData.isAgreementSigned == 0 && <h4 className={cx(cs.commonTotalText, cs.fontWeightLight, cs.paddingBottom4)}>{formatMessage(messages.signUpAgreementInfo)}</h4>}
                    {/* {loading  && <Loader type={"text"} />} */}
                    { isHavingError == true && <div>
                        <h4 className={cx(cs.agreementHeaderTop,cs.agreementTitleNotification,cs.fontWeightLight, cs.paddingBottom4)}>{formatMessage(messages.infoStatAgreementMissed)}</h4>
                        
                    </div>
                    }

                    <ShowNotification
                        handleHideError={this.handleHideError}
                        handleShowError={this.handleShowError}    
                        showEmailConfirmed={true}
                        showPhoneNumber={true}
                        showAgreement={true}
                        showVehicleDoc={false}
                        showIDIdentification={true}
                        showDriverLicense={true}
                        showDocVerification={true}
                        listingID={0}
                        />

                    { !isHavingError && <div>
                        <form onSubmit={handleSubmit(submit)} className={cx('noScroll', 'inputFocusColor')}>
                            
                            <div className={cx(s.containerAgreement)}>
                                {!agreementSign && <p className={cx(s.agreementInfo)}>{formatMessage(messages.infoClickAgreement)}</p>}
                                
                                {account && account.userProfileData.isAgreementSigned == 1 && account.userProfileData.isAgreementStatus == 1 && <div className={cx(s.containerStatReview)}>
                                    <p>{account.displayName}, {formatMessage(messages.agreementInfoPending)}</p>
                                    <p>{formatMessage(messages.agreementInfoPending2)} <strong>{agreementNum}</strong> {formatMessage(messages.agreementInfoPending3)} 
                                     <strong> {agreementDealerSigned}</strong>.</p>
                                    </div>
                                }
                                {account && account.userProfileData.isAgreementSigned == 1 && account.userProfileData.isAgreementStatus == 2 && <div className={cx(s.containerStatReview)}>
                                    <p>{account.displayName}, {formatMessage(messages.agreementInfoAccepted)}</p>
                                    <p>{formatMessage(messages.agreementInfoAccepted2)} <strong>{agreementNum}</strong> {formatMessage(messages.agreementInfoAccepted3)} 
                                    <strong> {agreementAccepted}</strong>.</p>
                                    </div>
                                }

                                {account && showDocFile && <a className={cx(s.agreementPDFView)} target='_blank' href={"/get-pdf-agreement/?pdfname=agreement_"+account.userId+".pdf"}>
                                    <img className={cx(s.agreementView)} src={"/get-img-agreement/?fileid=agreement_"+account.userId+".jpeg"} />
                                </a>}
                                
                                <Field component={CommonFormComponent} name="loggedinEmail" type="hidden" />
                                <Field component={CommonFormComponent} name="loggedinUser" type="hidden" />
                                <Field component={CommonFormComponent} name="agreementId" type="hidden" />
                                {account && account.userProfileData.isAgreementSigned == 0 && <Field name="iAgreeTerms" component={this.checkboxGroup} />}
                            </div>
                           {/* {account && !isUnderReview && !agreementSign && agreementStatus == 0 && <Button
                                className={cx(cs.btnPrimary, cs.spaceBottom2)}
                                bsSize="large"
                                block type="submit"
                                disabled={btnCondictionDisabled}
                            > */}
                           {account && account.userProfileData.isAgreementSigned == 0 && <Button
                                className={cx(cs.btnPrimary, cs.spaceBottom2)}
                                bsSize="large"
                                block type="submit"
                                disabled={btnCondictionDisabled}
                            >
                                
                                {formatMessage(messages.signLabel)}
                                <img src={arrow} className={cx(cs.blueLeftArrow, 'loginArrowRTL')} />
                            </Button>}
                        </form>
                        </div>
                        }
                </div>}

            </Col>
        );
    }
}

AgreementsPreview = reduxForm({
    form: 'AgreementsPreview', // a unique name for this form
    validate,
    onSubmitFail
  })(AgreementsPreview);

const mapState = (state) => ({
    account: state.account.data,
    
});

const mapDispatch = {
    change
};

export default compose(
    injectIntl,
    connect(mapState, mapDispatch),
    withStyles(s,cs),
    graphql(userAccountQuery, {
        name: 'userAccountData',
        options: {
        ssr: false,
        fetchPolicy: 'network-only'
        }
    }),
)(AgreementsPreview);