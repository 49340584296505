// General
import React from 'react';
import PropTypes from 'prop-types';
// import { graphql, gql, compose } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';

import Button from 'react-bootstrap/lib/Button';
// import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Webcam from "react-webcam";

// Redux
import { createReservationPhoto } from '../../actions/manageListPhotosLibrary';

// Asset
import defaultPic from '/public/siteImages/defaultPic.jpg';
import carFrontSide from '/public/carfrontside.png';
import imgVehicle from '/public/siteImages/car_sideview/driver_front_door.svg';

import cx from 'classnames';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './CarTakePhoto.css';
import cs from '../../components/commonStyle.css';
import { genUID } from '../../helpers/admGetDataField';
// Locale
import messages from '../../locale/messages';
// Component 
import Link from '../Link';

class CarTakePhoto extends React.Component {
  static propTypes = {
    vehiclePhotoType: PropTypes.number.isRequired,
    createReservationPhoto: PropTypes.any.isRequired,
    formatMessage: PropTypes.any,
    photoList: PropTypes.array,
    idNumber: PropTypes.number,
    reservationId: PropTypes.number,
    listId: PropTypes.number,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    fileType: PropTypes.string,
    className: PropTypes.string,
    pic: PropTypes.bool,
    picTook: PropTypes.string,
    picturesList: PropTypes.array,
    // staticImage: PropTypes.bool
  };

  static defaultProps = {
    // source: null,
    picTook: null,
    pic: false,
    picturesList:[],
    photoList:[]
    
  }

  constructor(props) {
    super(props);
    this.state = {
      pic:false,
      picturesList:[],
      screenshot: null,
    }
    this.showCamera = this.showCamera.bind(this);
    this.takePhoto = this.takePhoto.bind(this);
    this.confirmPhoto = this.confirmPhoto.bind(this);
    this.reTakePhoto = this.reTakePhoto.bind(this);
    this.hideCamera = this.hideCamera.bind(this);
    // this.paginationData = this.paginationData.bind(this);
  };
  
  showCamera(){
    this.setState({pic:true});
  }
  
  hideCamera(){
    this.setState({
      pic:false,
      picTook:null
    });
  }
  takePhoto(e){
    this.setState({picTook:e});
    this.setState({pic:false});
  }
  reTakePhoto(e){
    this.setState({
      pic:true,
      picTook:null
    });
  }
  
  async confirmPhoto(e){
    const {picturesList,picTook}=this.state;
    let addNewPicture = picturesList;  addNewPicture.push(picTook);
    
    const filename = genUID(4)+'-'+genUID(5)+'-'+genUID(4);
    const {reservationId,listId,fileType,createReservationPhoto,idNumber,vehiclePhotoType} = this.props; 
    const resp = await fetch('/uploadTripsPhoto', {
        method: "POST", body: JSON.stringify({ file: picTook,filename:filename,fileType:fileType }), credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
    });
    const data = await resp.json();
    
    if (data?.status == 'success') {
      createReservationPhoto(reservationId,listId,filename+'.jpg',fileType,vehiclePhotoType,0);
      this.setState({picturesList:addNewPicture});
      this.setState({picTook:null});
      this.setState({pic:false});
    }
    
  }

  async removeImage(image) {
    // const { change, claimImages } = this.props;
    // let updatedClaimImages = [...claimImages];
    // const resp = await fetch('/remove/claim/photos', {
    //     method: "POST", body: JSON.stringify({ filename: image }), credentials: 'include',
    //     headers: {
    //         'Content-Type': 'application/json'
    //     },
    // });
    // const data = await resp.json();
    // if (data?.status == 200) {
    //     this.setImageLoader(true);
    //     showToaster({ messageId: 'imageRemoved', toasterType: 'success' })
    //     if (Array.isArray(claimImages)) {
    //         let index = claimImages.findIndex(item => item == image);
    //         if (index > -1) updatedClaimImages.splice(index, 1);
    //     }
    //     change('claimImages', updatedClaimImages);
    //     this.setImageLoader(false);
    // }
  }

  render() {
    const {title,subtitle,photoList,fileType,idNumber,vehiclePhotoType} = this.props;
    
    const {pic, picTook,picturesList} = this.state;
    const videoConstraints = { width: 500, height: 500, facingMode: "user"};

    return (
      <div className={cx(s.itemTakePhotoUI)}>
        <Row className={cx(s.rowPaddingBottom,s.carTakePhotoBorder)}> 
          <Col lg={6} md={6} xs={12} sm={12} className={cx(cs.textAlignLeft)}>
            <Row>
              {/* <img onClick={this.showCamera} src={carFrontSide} className={cx(s.commonIconcar)} /> */}
              {/* <img onClick={this.showCamera} src={`./public/siteImages/car_sideview/${fileType}.svg`} className={cx(s.commonIconcar)} /> */}
              <img onClick={this.showCamera} src={imgVehicle} className={cx(s.commonIconcar)} />
              {/* <p>{idNumber+'.'+title}
              <span className={cx(s.subtitleCarTakePhoto)}>{subtitle}</span>
              </p> */}
            </Row>
            <Row className={cx(s.rTextAlignLeft)}>
              {photoList != null && photoList.length > 0 && photoList.map((item,key) => {
                  return (item.type == fileType && item.photo_type == vehiclePhotoType && <div key={key} className={cx(s.vehicleTookPhotoContent)}>
                        <img  className={cx(s.imgSaved)} src={'/images/trips/small_'+item.name} />
                        <span>{moment(item.createdAt).format('DD-MM-YYYY hh:mm A').toString()}</span>
                      </div>
                  )
              })}
            </Row>
            <Row className={cx(s.rTextAlignLeft)}>
              {picturesList != null && picturesList.length > 0 && picturesList.map((item,key) => {
                  return (
                  <img key={key} className={cx(s.imgBorder)} src={item} />
                  )
              })}
            </Row>
          </Col>
          <Col lg={6} md={6} xs={12} sm={12} className={cx(cs.textAlignCenter)}>
          
          {pic==true && <Webcam
            id='webcam'
            className={cx(s.carCameraContent)}
            audio={false}
            height={500}
            screenshotFormat="image/jpeg"
            width={500}
            videoConstraints={videoConstraints}
          >
            {({ getScreenshot }) => (
              <div>
              <Button
              className={cx(s.btnDisplayInLine,cs.btnPrimary,cs.spaceTop3, cs.spaceBottom2)}
                onClick={(e) => {
                  const imageSrc = getScreenshot();
                  // this.setState({picTook:imageSrc});
                  //this.setState({pic:false});
                  this.takePhoto(imageSrc)
                }}
              >
                <FormattedMessage {...messages.CapturePhoto} />
              </Button>

              <Button
                className={cx(s.btnDisplayInLine,cs.btnPrimary,cs.btnCancelTH,cs.spaceTop3, cs.spaceBottom2)}
                onClick={this.hideCamera}
              >
                <FormattedMessage {...messages.cancel} />
              </Button>
              </div>
            )}

          </Webcam>}

          
          {picTook != null &&
            <div>
               
              <img className={cx(s.carCameraContent)} src={picTook} />
              <Button
                className={cx(s.btnDisplayInLine,cs.btnPrimary,cs.spaceTop3, cs.spaceBottom2)}
                onClick={this.confirmPhoto}
              >
                <FormattedMessage {...messages.confirmPhoto} />
              </Button>
              <Button
                className={cx(s.btnDisplayInLine,cs.btnPrimary,cs.spaceTop3, cs.spaceBottom2)}
                onClick={this.reTakePhoto}
              >
                <FormattedMessage {...messages.reTakePhoto} />
              </Button>
              <Button
                className={cx(s.btnDisplayInLine,cs.btnPrimary,cs.btnCancelTH,cs.spaceTop3, cs.spaceBottom2)}
                onClick={this.hideCamera}
              >
                <FormattedMessage {...messages.cancel} />
              </Button>
            </div>
          }
          
          </Col>
        </Row>
      </div>
      
      
    );

  }
}

const mapState = (state) => ({
  // maxUploadSize: state.siteSettings.data.maxUploadSize
});

const mapDispatch = {
  createReservationPhoto,
  // removeListPhotos,
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(CarTakePhoto)));

